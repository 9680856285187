export const endpoints: any = {
  // Endpoints del CRUD de usuarios
  user: {
    login: "/auth/login", // Inicio de sesion
    create: "/user/create", // Crear usuarios
    list: "/user/list", // Listar usuarios / puede llevar 2 parametros para el rango de registros - ?since=&limit=
    update: "/user/update/", // Actualizar usuarios / se le concatena el uid del usuario
    updateData: "/user/data/update", // Actualizar programa de un registro
    delete: "/user/delete/", // eliminar usuarios / se le concatena el uid del usuario
    forgotPassword: "/auth/forgotpassword", // Recuperacion de contraseñas
  },
  // Endpoints del CRUD de roles
  rol: {
    create: "/rol/create",
    list: "/rol/list",
    update: "/rol/update/",
    delete: "/rol/delete/",
  },
  // Endpoints del CRUD de tasas
  rates: {
    // Tasa de Ausencia Intersemestral
    TAI: {
      create: "/tasa/tai",
      list: "/tasa/list-tai",
    },
    TDA: {
      create: "/tasa/tda",
      list: "/tasa//list-tda",
    },
    TDC: "/tasa/list", // Tasa de Desercion por Cohorte
    TGA: "/tasa/list", // Tasa de Graduacion Anual
    TGC: "/tasa/list", // Tasa de Graduacion por Cohorte
  },

  // Crear tablas - Cargar plantillas
  templates: {
    create: {
      1: "/participant/create", // Participantes
      2: "/registered/create", // Inscritos - Relacion de inscritos
      3: "/registeredProgram/create", // Inscritos - programas
      4: "/admitted/create", // Admitidos
      5: "/studentsFirstCourse/create", // Primer curso
      6: "/enrolled/create", // Matriculados
      7: "/graduate/create", // Graduados
      8: "/program/create", // Programas
    },

    // Listar tablas - Listar plantillas
    list: {
      1: "/participant/list", // Participantes
      2: "/registered/list", // Inscritos - Relacion de inscritos
      3: "/registeredProgram/list", // Inscritos - programas
      4: "/admitted/list", // Admitidos
      5: "/studentsFirstCourse/list", // Primer curso
      6: "/enrolled/list", // Matriculados
      7: "/graduate/list", // Graduados
      8: "/program/list", // Programas
      9: "/information/tables", // nombres de plantillas
    },
  },
  programs: {
    list: "/program/filters", // Listar todos los programas
    filters: "/program/filters", // Listar todos los filtros que se obtiene desde los programas
    associated: "/program/programassociated", // Listar los programas asociados a un estudiante
  },
  reinstate: {
    list: "/request/reintegro", // Listar todos las solicitudes de reintegros
    information: "/information", // Listar todos los filtros de las solicitudes de reintegros
    statistics: "/statistics/reintegro", // Listar todos los resultados para la grafica de la lista de solicitudes de reintegros
  },
  filters: {
    rol: "/rol/list", // listar todos los roles que existen
    templates: "/information/datatablebydate", // lista la plantilla por fecha de actualizaciones
  },
  individuals: {
    getInfoStudents: "/enrolled/filter", // Obtener la informacion completa de los estudiantes ausentes y desertores
  },
  permissions: {
    list: "/permission/list", // Obtener todos los permisos que existen
    user: {
      list: "/userpermission/list", // Obtener todos los permisos de un usuario + parametro ?id=
      update: "/userpermission/update/", // Actualizar los permisos de un usuario
    },
  },
};
