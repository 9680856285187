import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import { Brand } from "../../components/forms/dataForms";
import {
  futureYears,
  percentages,
  semesters,
} from "../administration/import/data";
import TableGoals from "./TableGoals";

const Goals = () => {
  return (
    <div>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Indicadores y metas
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb>
            <Breadcrumb.Item className=" tx-15" href="#">
              Estadisticas
            </Breadcrumb.Item>
            <Breadcrumb.Item>Calculo de tasas</Breadcrumb.Item>
            <Breadcrumb.Item active aria-current="page">
              Indicadores y metas
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Row style={{ marginBottom: "30px" }}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
          <Card className="custom-card">
            <Card.Header className="border-bottom-0">
              <div>
                <h3 className="card-title mb-2 ">Crear meta</h3>
                <span className="d-block tx-12 mb-0 text-muted"></span>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={12} md={12} lg={4} xl={4}>
                  <FormGroup className="form-group">
                    <Form.Label className="form-label text-dark">
                      Año
                    </Form.Label>
                    <Brand
                      Options={futureYears()}
                      Placeholder="Seleccionar año"
                      Name="areaFilters"
                      // Onchange={handleSelectChange}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={12} lg={4} xl={4}>
                  <FormGroup className="form-group">
                    <Form.Label className="form-label text-dark">
                      Semestre
                    </Form.Label>
                    <Brand
                      Options={semesters}
                      Placeholder="Seleccionar semestre"
                      Name="semester"
                      // Onchange={handleSelectChange}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={12} lg={4} xl={4}>
                  <FormGroup className="form-group">
                    <Form.Label className="form-label text-dark">
                      Porcentaje (%)
                    </Form.Label>
                    <Brand
                      Options={percentages()}
                      Placeholder="Seleccionar porcentaje"
                      Name="percentage"
                      // Onchange={handleSelectChange}
                    />
                  </FormGroup>
                </Col>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ textAlign: "center" }}
                >
                  <Button className="btn btn-primary">Crear meta</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <TableGoals />
      </Row>
    </div>
  );
};

export default Goals;
