import React from "react";
import { Route, Routes } from "react-router-dom";
import Auth from "../Authentication/auth";
import { PERMISSIONS } from "../data/permissions";
import App from "../shade/layouts/App";
import Loader from "../shade/Loaders/Loaders";
import { PrivateRoute } from "./protectedRoutes/PrivateRoute";
import { PublicRoute } from "./protectedRoutes/PublicRoute";
import ModifyDownload from "../pages/administration/import/ModifyDownload";
import NonGraduates from "../pages/nongraduates/NonGraduates";
import Goals from "../pages/goals/Goals";

export const Router = () => {
  React.useEffect(() => {
    localStorage.setItem("nowadark", "true");
  }, []);

  // Public Routes
  const AuthLogin = React.lazy(() => import("../Authentication/Login"));

  // Private Routes
  const Analitics = React.lazy(() => import("../pages/analitics/Analitics"));
  const AdminUsers = React.lazy(() =>
    import("../pages/administration/users/Users")
  );
  const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
  const ImportInfo = React.lazy(() =>
    import("../pages/administration/import/ImportInfo")
  );
  const ListStudents = React.lazy(() =>
    import("../pages/administration/students/ListStudents")
  );
  const ModifyTemplates = React.lazy(() =>
    import("../pages/administration/import/ModifyTemplates")
  );
  const Permissions = React.lazy(() =>
    import("../pages/administration/roles/Roles")
  );
  const Application = React.lazy(() =>
    import("../pages/application/Application")
  );
  const UserPermissions = React.lazy(() =>
    import("../pages/administration/permissions/UserPermissions")
  );
  const UserProfile = React.lazy(() => import("../pages/user/UserProfile"));

  // Routes errors Pages
  const Error404 = React.lazy(() => import("../pages/errors/404Error"));
  const Custompages = React.lazy(() => import("../shade/layouts/custompages"));

  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route path={`/`} element={<Auth />}>
          <Route
            index
            element={
              <PublicRoute>
                <AuthLogin />
              </PublicRoute>
            }
          />
        </Route>

        <Route path={`/`} element={<App />}>
          <Route
            path={`/administration/users`}
            element={
              <PrivateRoute permissions={PERMISSIONS.readUser}>
                <AdminUsers />
              </PrivateRoute>
            }
          />
          <Route
            path={`/administration/import`}
            element={
              <PrivateRoute permissions={PERMISSIONS.writeTemplates}>
                <ImportInfo />
              </PrivateRoute>
            }
          />
          <Route
            path={`/administration/list-students`}
            element={
              <PrivateRoute permissions={PERMISSIONS.readTemplates}>
                <ListStudents />
              </PrivateRoute>
            }
          />
          <Route
            path={`/administration/modify-template`}
            element={
              <PrivateRoute permissions={PERMISSIONS.readTemplates}>
                <ModifyTemplates />
              </PrivateRoute>
            }
          />
          <Route
            path={`/administration/modify-download`}
            element={
              <PrivateRoute permissions={PERMISSIONS.downloadUpdateTemplates}>
                <ModifyDownload />
              </PrivateRoute>
            }
          />
          <Route
            path={`/administration/permissions`}
            element={
              <PrivateRoute>
                <Permissions />
              </PrivateRoute>
            }
          />
          <Route
            path={`/administration/user-permissions/:uid`}
            element={
              <PrivateRoute>
                <UserPermissions />
              </PrivateRoute>
            }
          />
          <Route
            path={`/analitics`}
            element={
              <PrivateRoute permissions={PERMISSIONS.readRate}>
                <Analitics />
              </PrivateRoute>
            }
          />
          <Route
            path={`/analitics/:endpoint`}
            element={
              <PrivateRoute permissions={PERMISSIONS.readRate}>
                <Analitics />
              </PrivateRoute>
            }
          />
          <Route
            path={`/dashboard`}
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={`/application`}
            element={
              <PrivateRoute permissions={PERMISSIONS.writeApplication}>
                <Application />
              </PrivateRoute>
            }
          />
          <Route
            path={`/profile`}
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={`/non-graduates`}
            element={
              <PrivateRoute>
                <NonGraduates />
              </PrivateRoute>
            }
          />
          <Route
            path={`/goals`}
            element={
              <PrivateRoute>
                <Goals />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path={`${process.env.PUBLIC_URL}/`} element={<Custompages />}>
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
};
