/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Fetch, IFetchProps } from "../../services/Fetch";
import { endpoints } from "../../data/endpoints";
import DataTable from "react-data-table-component";
import { customStyles } from "../../components/tables/TableStyles";
import PaginationComponent from "../../components/tables/PaginationComponent";

const TableGoals = () => {
  const { token } = useSelector((state: any) => state.auth.user);
  const [data, setData] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);

  const [since, setSince] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  useEffect(() => {
    getDataStudents();
  }, [since, limit]);

  const getDataStudents = async () => {
    const propsFetch: IFetchProps = {
      Endpoint: `${endpoints.individuals.getInfoStudents}?since=${since}&limit=${limit}`,
      method: "POST",
      data,
      token,
    };
    const response: any = await Fetch(propsFetch);
    if (response.statusCode && response.statusCode === 200) {
      setColumnsData(response.data);
    }
  };

  const setColumnsData = (datatable: any) => {
    const cols = Object.keys(datatable[0]);
    const columnsData = [];

    for (let index = 0; index < cols.length; index++) {
      const col = cols[index];

      columnsData.push({
        name: col,
        selector: (row: any) => [row[col]],
        sortable: true,
        cell: (row: any) => (
          <span>
            <span className="text-light tx-13">{row[col] ? row[col] : ""}</span>
          </span>
        ),
      });
    }
    setColumns(columnsData);
    setData(datatable);
    setTotal(10);
  };
  return (
    <Col md={12} xl={12} xs={12} sm={12}>
      <Card>
        <Card.Header>
          <h3 className="card-title">PROYECCION DE METAS</h3>
        </Card.Header>
        <Card.Body>
          <div>
            <div className="table-responsive ">
              <span className="datatable">
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  noDataComponent={
                    <div style={{ margin: "50px 10px" }}>
                      No se encontraron datos de proyeccion de metas
                    </div>
                  }
                />
                {data.length > 0 && (
                  <PaginationComponent
                    since={since}
                    setSince={setSince}
                    limit={limit}
                    setLimit={setLimit}
                    total={total}
                  />
                )}
              </span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default TableGoals;
