import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import { Brand } from "../../components/forms/dataForms";
import DataTable from "react-data-table-component";
import { customStyles } from "../../components/tables/TableStyles";
import PaginationComponent from "../../components/tables/PaginationComponent";
import FilterSelects from "../../components/forms/FilterSelects";

const NonGraduates = () => {
  const [view, setView] = useState<any>("basic");
  const [data, setData] = React.useState<any>([{ name: "" }]);
  const [columns, setColumns] = React.useState<any>([
    {
      name: "Periodo",
      selector: (row: any) => [row.name],
      sortable: true,
      cell: (row: any) => (
        <span>
          <p className="tx-14 font-weight-semibold text-dark mb-1">
            {row.name}
          </p>
        </span>
      ),
    },
    {
      name: "Codigo",
      selector: (row: any) => [row.name],
      sortable: true,
      cell: (row: any) => (
        <span>
          <p className="tx-14 font-weight-semibold text-dark mb-1">
            {row.name}
          </p>
        </span>
      ),
    },
    {
      name: "Nombre",
      selector: (row: any) => [row.name],
      sortable: true,
      cell: (row: any) => (
        <span>
          <p className="tx-14 font-weight-semibold text-dark mb-1">
            {row.name}
          </p>
        </span>
      ),
    },
    {
      name: "Nota",
      selector: (row: any) => [row.name],
      sortable: true,
      cell: (row: any) => (
        <span>
          <p className="tx-14 font-weight-semibold text-dark mb-1">
            {row.name}
          </p>
        </span>
      ),
    },
    {
      name: "Estado",
      selector: (row: any) => [row.name],
      sortable: true,
      cell: (row: any) => (
        <span>
          <p className="tx-14 font-weight-semibold text-dark mb-1">
            {row.name}
          </p>
        </span>
      ),
    },
  ]);

  const [total, setTotal] = React.useState<number>(1);
  const [since, setSince] = React.useState<number>(0);
  const [limit, setLimit] = React.useState<number>(10);

  return (
    <div>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Egresados no graduados
          </span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb>
            <Breadcrumb.Item className=" tx-15" href="#">
              Estadisticas
            </Breadcrumb.Item>
            <Breadcrumb.Item>Calculo de tasas</Breadcrumb.Item>
            <Breadcrumb.Item active aria-current="page">
              Egresados no graduados
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Row style={{ marginBottom: "30px" }}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
          <Button
            className="btn btn-primary"
            onClick={() => setView("basic")}
            style={{ marginRight: "10px" }}
          >
            Consulta Basica
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => setView("analitic")}
          >
            Consulta Analitica
          </Button>
        </Col>
      </Row>
      {view === "basic" ? (
        <Row>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
            <Card className="custom-card">
              <Card.Header className="border-bottom-0">
                <div>
                  <h3 className="card-title mb-2 ">Consultar estudiante</h3>
                  <span className="d-block tx-12 mb-0 text-muted"></span>
                </div>
              </Card.Header>
              <Card.Body>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                  <FormGroup className="form-group">
                    <Form.Label className="form-label text-dark">
                      Tipo de documento
                    </Form.Label>
                    <Brand
                      Options={[]}
                      Placeholder="Seleccionar tipo de documento"
                      Name="rate"
                      // Onchange={handleSelectChange}
                    />
                  </FormGroup>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                  <FormGroup className="form-group">
                    <Form.Label htmlFor="num_identificacion">
                      Numero de documento
                    </Form.Label>
                    <Form.Control
                      placeholder=" Numero de documento"
                      type="text"
                      name="num_identificacion"
                      // onChange={handleChangeInput}
                      // value={data.num_identificacion ?? ""}
                    />
                  </FormGroup>
                </Col>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    className="btn btn-primary w-100"
                    onClick={() => setView("basic")}
                  >
                    Consultar
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
            <Card className="custom-card">
              <Card.Header className="border-bottom-0">
                <div>
                  <h3 className="card-title mb-2 ">Consultar asignaturas</h3>
                  <span className="d-block tx-12 mb-0 text-muted"></span>
                </div>
              </Card.Header>
              <Card.Body>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                  <FormGroup className="form-group">
                    <Form.Label className="form-label text-dark">
                      Programa
                    </Form.Label>
                    <Brand
                      Options={[]}
                      Placeholder="Seleccionar programa"
                      Name="rate"
                      // Onchange={handleSelectChange}
                    />
                  </FormGroup>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                  <FormGroup className="form-group">
                    <Form.Label className="form-label text-dark">
                      Pensum
                    </Form.Label>
                    <Brand
                      Options={[]}
                      Placeholder="Seleccionar pensum"
                      Name="rate"
                      // Onchange={handleSelectChange}
                    />
                  </FormGroup>
                </Col>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    className="btn btn-primary w-100"
                    onClick={() => setView("basic")}
                  >
                    Consultar
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12} xl={12} xs={12} sm={12}>
            <Card>
              <Card.Header>
                <h3 className="card-title">Listado de asignaturas</h3>
              </Card.Header>
              <Card.Body>
                <div>
                  <div className="table-responsive ">
                    <span className="datatable">
                      <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                      />
                      {data.length > 0 && (
                        <PaginationComponent
                          since={since}
                          setSince={setSince}
                          limit={limit}
                          setLimit={setLimit}
                          total={total}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <FilterSelects onSubmit={() => setView("List")} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default NonGraduates;
