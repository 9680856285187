/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { Brand, OptionsProps } from "./dataForms";
import { Fetch, IFetchProps } from "../../services/Fetch";
import { endpoints } from "../../data/endpoints";
import { useSelector } from "react-redux";

interface FilterSelectsProps {
  onSubmit: (value?: any) => void;
}

const FilterSelects = ({ onSubmit }: FilterSelectsProps) => {
  const { token } = useSelector((state: any) => state.auth.user);
  const [data, setData] = useState<any>();
  const [filterSelects, setFilterSelects] = useState<any>({});

  const setFilters = (response: any) => {
    const { programa, msg, statusCode, ...res } = response;
    const program: OptionsProps[] = [];

    programa.forEach((pro: any) => {
      program.push({
        label: pro.code_snies_program + " - " + pro.program_name,
        value: pro.code_snies_program,
      });
    });
    const keys = Object.keys(res);
    let sel = { programs: program };
    keys.forEach((key: any) => {
      const options: OptionsProps[] = [];
      res[key].forEach((k: any) => {
        options.push({
          label: k,
          value: k,
        });
      });
      sel = { ...sel, [key]: options };
    });
    console.log(sel)
    setData({ ...sel });
  };

  // funcion para consultar todos los programas y algunas de sus carateristicas para eleccion en los filtros
  const getFilters = async () => {
    const queryParams = Object.entries(filterSelects)
      .map(([key, value]: any) => `${key}=${value}`)
      .join("&");

    const propsFetch: IFetchProps = {
      Endpoint: `${endpoints.programs.filters}?${queryParams}`,
      method: "GET",
      token,
    };
    const response: any = await Fetch(propsFetch);
    if (response.statusCode && response.statusCode === 200) {
      setFilters(response);
    }
  };

  const handleSelectChange = (opt: any) => {
    setFilterSelects({ ...filterSelects, [opt.Name]: opt.value });
  };

  useEffect(() => {
    getFilters();
  }, [filterSelects]);

  return (
    <Card>
      <Card.Header>
        <h3 className="card-title">CRITERIOS DE CONSULTA POR PROGRAMAS</h3>
      </Card.Header>
      <Card.Body>
        <div>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <FormGroup className="form-group">
                <Form.Label className="form-label text-dark">
                  Area de Conocimiento
                </Form.Label>
                <Brand
                  Options={data?.areaC ?? []}
                  Placeholder="Seleccionar Area de Conocimiento"
                  Name="areaFilters"
                  Onchange={handleSelectChange}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12}>
              <FormGroup className="form-group">
                <Form.Label className="form-label text-dark">
                  Nucleo Basico de Conocimineto
                </Form.Label>
                <Brand
                  Options={data?.nucleoBC ?? []}
                  Placeholder="Seleccionar Nucleo Basico de Conocimineto"
                  Name="coreFilters"
                  Onchange={handleSelectChange}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12}>
              <FormGroup className="form-group">
                <Form.Label className="form-label text-dark">
                  Metodologia del programa
                </Form.Label>
                <Brand
                  Options={data?.Modalidad ?? []}
                  Placeholder="Seleccionar Metodologia del programa"
                  Name="modalityFilters"
                  Onchange={handleSelectChange}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12}>
              <FormGroup className="form-group">
                <Form.Label className="form-label text-dark">
                  Nivel de Formacion
                </Form.Label>
                <Brand
                  Options={data?.nivelFormacion ?? []}
                  Placeholder="Seleccionar Nivel de Formacion"
                  Name="levelCourseFilters"
                  Onchange={handleSelectChange}
                />
              </FormGroup>
            </Col>

            <Col sm={12} md={12} lg={12} xl={12}>
              <FormGroup className="form-group">
                <Form.Label className="form-label text-dark">
                  Departamento
                </Form.Label>
                <Brand
                  Options={data?.departamentoOP ?? []}
                  Placeholder="Seleccionar Departamento"
                  Name="departamentFilters"
                  Onchange={handleSelectChange}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12}>
              <FormGroup className="form-group">
                <Form.Label className="form-label text-dark">
                  Municipio
                </Form.Label>
                <Brand
                  Options={data?.municipioOP ?? []}
                  Placeholder="Seleccionar Municipio"
                  Name="municipalityFilters"
                  Onchange={handleSelectChange}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12}>
              <FormGroup className="form-group">
                <Form.Label className="form-label text-dark">
                  Programa académico
                </Form.Label>
                <Brand
                  Options={data?.programs ?? []}
                  Placeholder="Seleccionar Programa académico"
                  Name="programFilters"
                  Onchange={handleSelectChange}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12}>
              <FormGroup className="form-group">
                <Form.Label className="form-label text-dark">Pensum</Form.Label>
                <Brand
                  Options={data?.programs ?? []}
                  Placeholder="Seleccionar pensum"
                  Name="pensum"
                  // Onchange={handleSelectChange}
                />
              </FormGroup>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              style={{ textAlign: "center" }}
            >
              <Button className="btn btn-primary w-100" onClick={onSubmit}>
                Consultar
              </Button>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FilterSelects;
