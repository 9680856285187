import { OptionsProps } from "../../../components/forms/dataForms";

interface yearsProps {
  semesters?: boolean;
}

export const templates: OptionsProps[] = [
  {
    label: "PARTICIPANTES",
    value: 1,
  },
  {
    label: "INSCRITOS - RELACION DE INSCRITOS",
    value: 2,
  },
  {
    label: "INSCRITO - PROGRAMA",
    value: 3,
  },
  {
    label: "ADMITIDOS",
    value: 4,
  },
  {
    label: "PRIMER CURSO",
    value: 5,
  },
  {
    label: "MATRICULADOS",
    value: 6,
  },
  {
    label: "GRADUADOS",
    value: 7,
  },
  {
    label: "PROGRAMAS",
    value: 8,
  },
];

export const years = (props?: yearsProps) => {
  const myDate = new Date().getFullYear();
  const years = [];
  for (let i = myDate; i >= 2007; i--) {
    if (props?.semesters) {
      years.push(
        ...[
          {
            label: `${i.toString()}-2`,
            value: `${i.toString()}-2`,
          },
          {
            label: `${i.toString()}-1`,
            value: `${i.toString()}-1`,
          },
        ]
      );
    } else {
      years.push({
        label: i.toString(),
        value: i.toString(),
      });
    }
  }
  return years;
};

export const futureYears = (props?: yearsProps) => {
  const myDate = new Date().getFullYear();
  const years = [];
  console.log(myDate);
  for (let i = myDate; i <= myDate + 20; i++) {
    if (props?.semesters) {
      years.push(
        ...[
          {
            label: `${i.toString()}-1`,
            value: `${i.toString()}-1`,
          },
          {
            label: `${i.toString()}-2`,
            value: `${i.toString()}-2`,
          },
        ]
      );
    } else {
      years.push({
        label: i.toString(),
        value: i.toString(),
      });
    }
  }
  return years;
};

export const semesters: any = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
];

export const convertTemplates: any = {
  registred: "Inscritos-Relación de inscritos",
  registeredProgram: "Inscrito programa",
  admitted: "Admitidos",
  studentFirstCourse: "Primer curso",
  enrolled: "Matriculados",
  graduate: "Graduados",
};

export const percentages = () => {
  const array = [];
  for (let i = 1; i <= 99; i++) {
    array.push({
      label: i.toString(),
      value: i.toString(),
    });
  }
  return array;
};
